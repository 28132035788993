// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-deletion-js": () => import("./../../../src/pages/accountDeletion.js" /* webpackChunkName: "component---src-pages-account-deletion-js" */),
  "component---src-pages-application-process-js": () => import("./../../../src/pages/ApplicationProcess.js" /* webpackChunkName: "component---src-pages-application-process-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loan-introduction-js": () => import("./../../../src/pages/loanIntroduction.js" /* webpackChunkName: "component---src-pages-loan-introduction-js" */),
  "component---src-pages-one-bill-js": () => import("./../../../src/pages/oneBill.js" /* webpackChunkName: "component---src-pages-one-bill-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-repayment-by-easypaisa-js": () => import("./../../../src/pages/RepaymentByEasypaisa.js" /* webpackChunkName: "component---src-pages-repayment-by-easypaisa-js" */),
  "component---src-pages-repayment-viaby-ea-js": () => import("./../../../src/pages/repaymentViabyEA.js" /* webpackChunkName: "component---src-pages-repayment-viaby-ea-js" */),
  "component---src-pages-repayment-viaby-wa-js": () => import("./../../../src/pages/repaymentViabyWa.js" /* webpackChunkName: "component---src-pages-repayment-viaby-wa-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

